import { pvhApiBase } from "@/config/env";
import request from '@/router/axios';


//文件显示列表 查询
export const fileList = (page, pageSize, name, type, beginTime, endTime,  ) => {
    return request({
        url: pvhApiBase + "Tracing/GetSupportFiles",
        method: "get",
        params: {
            "name": name,
            "type": type,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "page": page,
            "pageSize": pageSize
        }
    })
}


//添加文件信息列表
export const addFile = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostSupportFile",
        method: "post",
        data: {
            ...row
        }
    })
}


//文件信息更新
export const editFile = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PutSupportFile",
        method: "put",
        data: {
            ...row
        }
    })
}


//删除文件信息
export const delFile = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteSupportFile",
        method: "delete",
        params: {
            id
        }
    })
}