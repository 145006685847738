<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('FileSettingList')}}</div>
            </div>
            <template>
                <avue-crud ref="crud" v-model="obj" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange" @row-update="handleUpdate" @row-del="delFile" @row-save="handleSave">
                    <template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">
                            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('NAME')}}:</span>
                                    <el-input v-model="nameVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span id="venSpan">{{$t('DATATYPE')}}:</span>
                                    <el-select v-model="dataTypeVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;" @change="venchang">
                                        <el-option v-for="item in this.typeData"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('BEINGTIME')}}:</span>
                                    <el-date-picker class="dataicon" v-model="beginTimeVal"
                                                    type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                    format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                    </el-date-picker>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('ENDTIME')}}:</span>
                                    <el-date-picker class="dataicon" v-model="endTimeVal"
                                                    type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                    format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                    </el-date-picker>
                                </el-col>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4"  style="padding-top: 25px; text-align: right; align-self: flex-end;">
                                <el-button type="primary" size="small"  @click="searchFrom">{{$t('SEARCH')}}</el-button>
                                <el-badge>
                                    <img style="padding-top: 4px; padding-right: 15px; padding-left: 15px; height: 1.7rem; width: 1.7rem;" @click="$refs.crud.rowAdd()"
                                         :title="$t('ADD')" src="/img/Bnt/add.png" />
                                </el-badge>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" @click="$refs.crud.rowEdit(row,index)"></el-button>
                    </template>
                    <template slot-scope="{row,index,type}" slot="menuForm">
                        <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
                        <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
                        <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
                    </template>
                </avue-crud>
            </template>
        </basic-container>
    </div>
</template>
<style>
    .dataicon i {
        display: none;
    }

    .othChe .el-checkbox__label {
        color: #409eff !important;
    }
</style>
<script>
   
    import { fileList, editFile, addFile, delFile } from "@/api/fileList";
    import { TracingUnifiedIsBool } from "@/api/unified";
    export default {
        data() {
            return {
                obj: {},
                nameVal: "",
                dataTypeVal: {
                    ids: [],
                },
                beginTimeVal: "",
                endTimeVal: "",
                tableData: [],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev,next",
                    background: true,
                    pageSize: 10
                },
                YesNoData: [
                    {
                        label: this.$t('False'),
                        value: 0
                    }, {
                        label: this.$t('True'),
                        value: 1
                    }

                ],
                typeData: [
                    {
                        label: this.$t('PDF'),
                        value: "1"
                    },
                    {
                        label: this.$t('picture'),
                        value: "2"
                    },
                    {
                        label: this.$t('PDF&Picture'),
                        value: "3"
                    }

                ],
            }
        },
        computed: {
            setData() {
                var validateExist = (rule, value, callback) => {
                    if ((value != '' || value != null)) {
                        var ids = this.obj.id;
                        if (this.obj.id == undefined) {
                            ids = "";
                        }
                        TracingUnifiedIsBool("FileExist", ids, value).then(resu => {
                            var IsExist = resu.data;
                            if (!IsExist) {
                                callback(new Error(this.$t('NameIsExist')));
                            } else { callback(); }
                        }).catch(() => { callback(); });
                    } else {
                        callback();
                    }
                };
                var validateCodeExist = (rule, value, callback) => {
                    if ((value != '' || value != null)) {
                        var ids = this.obj.id;
                        if (this.obj.id == undefined) {
                            ids = "";
                        }
                        TracingUnifiedIsBool("FileCodeExist", ids, value).then(resu => {
                            var IsExist = resu.data;
                            if (!IsExist) {
                                callback(new Error(this.$t('CodeIsExist')));
                            } else { callback(); }
                        }).catch(() => { callback(); });
                    } else {
                        callback();
                    }
                };
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        delBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 140,
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                        addBtn: false,
                        editBtn: false,
                        header: true,
                        saveBtn: false,
                        updateBtn: false,
                        cancelBtn: false,
                        labelPosition: 'top',
                        column: [
                            {
                                label: this.$t('NAME'),
                                prop: 'name',
                                rules: [{ required: true, message: this.$t('Required'), trigger: 'blur' },
                                        { validator: validateExist, trigger: 'blur'}  ]
                            },
                            {
                                label: this.$t('CODE'),
                                prop: 'code',
                                rules: [{ required: true, message: this.$t('Required'), trigger: 'blur' },
                                    { validator: validateCodeExist, trigger: 'blur' }]
                            },
                            {
                                label: this.$t('DATATYPE'),
                                prop: 'type',
                                type: "select",
                                filterable: true,
                                dicData: this.typeData,
                                rules: [{
                                    required: true,
                                    message: this.$t('Pleaseselect'),
                                    trigger: 'blur'
                                }],

                            },
                            {
                                label: this.$t('ISNULL'),
                                prop: 'mustUpload',
                                type: "switch",
                                dicData: this.YesNoData,
                            },
                            {
                                label: this.$t('CREATTIME'),
                                prop: 'createTime',
                                type: "date",
                                editDisplay: false,//页面 隐藏
                                addDisplay: false,
                                format: "yyyy-MM-dd HH:mm:ss",
                                valueFormat: "yyyy-MM-dd HH:mm:ss"
                            },
                        ]
                    },
                }
            }
        },
        created() {
            this.getFileList();
        },
        methods: {
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                        obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                    } else {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                    }

                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else if (obj.currentTarget === null) {
                    if (this.dataTypeVal.ids.length === 0) {
                        document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                }
            },
            venchang() {
                if (this.dataTypeVal.ids.length === 0) {
                    document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                } else {
                    document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },//点击更多按钮
            rowStyle({ rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },//切换分页
            currentChange(val) {
                this.page.currentPage = val;
                this.getFileList();
            },//搜索
            searchFrom() {
                this.getFileList();
            },//编辑数据
            handleUpdate(row, done) {
                editFile(row).then(() => {
                    this.$message({ showClose: true, message: this.$t('UpdateSuccess'), type: "success" });
                    this.getFileList();
                    this.obj = {};
                })
                done();
            },//添加数据
            handleSave(row, done) {
                addFile(row).then(() => {
                    this.$message({ showClose: true, message: this.$t('AddSuccess'), type: "success" });
                    this.getFileList();
                    this.obj = {};
                    done();
                })
            },
            //删除数据
            delFile(row, done) {
                this.$confirm(this.$t('IsDel') + row.name, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    delFile(row.id).then(() => {
                        this.getFileList();
                        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                    })
                    done();
                })
            },//获取列表显示出数据
            async getFileList() {
                fileList(this.page.currentPage, this.page.pageSize, this.nameVal, this.dataTypeVal.ids.toString(), this.beginTimeVal, this.endTimeVal ).then(res => {
                    this.tableData = res.data.supportFiles;
                    this.page.total = res.data.totalCount;
                    this.page.pageSize = res.data.pageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            }
        }
    }
</script>